import React from 'react';
import {Button, Tag, message, Popconfirm} from 'antd';
import {getOutlet} from 'reconnect.js';
import {ExportOutlined} from '@ant-design/icons';
import {Download} from '@styled-icons/boxicons-regular/Download';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import DataJson from '../../../data.json';
import AdminLinkButton from '../../Generators/AdminResource/AdminLinkButton';
import AdminUserProfileNavigation from '../../Generators/AdminResource/AdminUserProfileNavigation';
import AdminProductNavigation from '../../Generators/AdminResource/AdminProductNavigation';
import AdminPrivateProfileCustom from '../../Generators/AdminResource/AdminPrivateProfileCustom';
import AdminFormActions from '../../Generators/AdminResource/AdminFormActions';
import AdminFormHistory from '../../Generators/AdminResource/AdminFormHistory';
import AdminFormReview from '../../Generators/AdminResource/AdminFormReview';
import AdminFormExecution from '../../Generators/AdminResource/AdminFormExecution';
import AdminFormCustomFields from '../../Generators/AdminResource/AdminFormCustomFields';
import AdminSignFormBasicInfo from '../../Generators/AdminResource/AdminSignFormBasicInfo';
import AdminDownloadExcelButton from '../../Components/AdminDownloadExcelButton';
import {showAdminImportExcelModal} from '../../Components/AdminImportExcelModal';
import {formatDateTimeStr, formatDateStr} from '../TimeUtil';
import permissionRequired from '../PermissionRequiredUtil';
import userPendingJson from '../../../content/official/user-pending.json';
import * as AppActions from '../../AppActions';
import * as CustomAppActions from '../../AppActions/custom';
import AdminMoraleCustomFields from '../../Generators/AdminResource/AdminMoraleCustomFields';
import AdminMoraleCustomActions from '../../Generators/AdminResource/AdminMoraleCustomActions';
import AdminProductCustomActions from '../../Generators/AdminResource/AdminProductCustomActions';
import AdminOrderNavigation from '../../Generators/AdminResource/AdminOrderDetailForm/AdminOrderNavigation';
import AdminOrderCustomActions from '../../Generators/AdminResource/AdminOrderCustomActions';
import AdminOrderDetailForm from '../../Generators/AdminResource/AdminOrderDetailForm';
import AdminOrderNotification from '../../Generators/AdminResource/AdminOrderNotification';
import AdminUserProfileCustomActions from '../../Generators/AdminResource/AdminUserPorfileCustomActions';
import AdminNewsCustomActions from '../../Generators/AdminResource/AdminNewsCustomActions';
import * as AdminMoraleWiget from '../../Generators/AdminResource/AdminMoraleWidget';
import AdminUserBasicInfo from '../../Generators/AdminResource/AdminUserBasicInfo';
import AdminUserAttendList from '../../Generators/AdminResource/AdminUserAttendList';
import AdminProductBasicInfo from '../../Generators/AdminResource/AdminProductBasicInfo';
import AdminOrderList from '../../Generators/AdminResource/AdminOrderList';
import AdminOrderFeeList from '../../Generators/AdminResource/AdminOrderFeeList';
import {getStaffValue} from '../../Utils/StaffUtil';
import {
  getUserTypeLabel,
  getUserStatusLabel,
  getUserStateLabel,
  getUserSubstateLabel,
  getUserInoutLeaveReasonLabel,
} from '../UserUtil';
import {
  getMeetingRoomLabel,
  getFormTypeLabel,
  getFormStageLabel,
  getFormStageLabelColor,
  getCompanyReviewStatusLabel,
  getCompanyReviewStatusLabelColor,
  getAnnualFeeTemplateNameLabel,
  getLegacyFormProgressLabel,
  getLegacyReceiveCategoryLabel,
  getLegacyPostCategoryLabel,
} from '../SelectOptionLabelUtil';
import AdminSignFormNavigation from '../../Generators/AdminResource/AdminSignFormNavigation';
import AdminMoraleNavigation from '../../Generators/AdminResource/AdminMoraleNavigation';
import AdminFormNavigation from '../../Generators/AdminResource/AdminFormNavigation';
import AdminSignFormActions from '../../Generators/AdminResource/AdminSignFormActions';
import AdminPayBillingActions from '../../Generators/AdminResource/AdminPayBillingActions';
import * as AdminPayBillingWidget from '../../Generators/AdminResource/AdminPayBillingWidget';
import AdminStaffCustomActions from '../../Generators/AdminResource/AdminStaffCustomActions';
import AdminLineDivider from '../../Components/AdminLineDivider';
import AdminMeetingNotification from '../../Generators/AdminResource/AdminMeetingNotification';
import AdminCompanyBillingActions from '../../Generators/AdminResource/AdminCompanyBillingActions';
import AdminCompanyBillingNavigation from '../../Generators/AdminResource/AdminCompanyBillingNavigation';
import AdminPayBillingNavigation from '../../Generators/AdminResource/AdminPayBillingNavigation';
import AdminUserBillingsCreateActions from '../../Generators/AdminResource/AdminUserBillingCreateActions';
import AdminUserBillingDiscountItemsList from '../../Generators/AdminResource/AdminUserBillingDiscountItemsList';
import AdminUserBillingAlert from '../../Generators/AdminResource/AdminUserBillingAlert';
import AdminWelfareBelowSection from '../../Generators/AdminResource/AdminWelfareBelowSection';
import AdminBackupEmailDataList from '../../Generators/AdminResource/AdminBackupEmailDataList';
import AdminBackupEmailExtraQueries from '../../Generators/AdminResource/AdminBackupEmailExtraQueries';
import AdminLegacyCourseNavigation from '../../Generators/AdminResource/AdminLegacyCourseNavigation';
import AdminLegacyActivityNavigation from '../../Generators/AdminResource/AdminLegacyActivityNavigation';
import AdminLegacyECourseNavigation from '../../Generators/AdminResource/AdminLegacyECourrseNavigation';
import AdminLegacyReceiveNavigation from '../../Generators/AdminResource/AdminLegacyReceiveNavigation';
import AdminLegacyPostNavigation from '../../Generators/AdminResource/AdminLegacyPostNavigation';
import AdminLegacyFormHistory from '../../Generators/AdminResource/AdminLegacyFormHistory';
import AdminReceiveAdvancedSearchButton from '../../Generators/AdminResource/AdminReceiveAdvancedSearchButton';
import AdminPostAdvancedSearchButton from '../../Generators/AdminResource/AdminPostAdvancedSearchButton';
import AdminProductAdvancedSearchButton from '../../Generators/AdminResource/AdminProductAdvancedSearchButton';

const UsersOutlet = getOutlet('users');
const UserOutlet = getOutlet('user');
const StaffsOutlet = getOutlet('staffs');
const ProductsOutlet = getOutlet('products');
const ReceivesOutlet = getOutlet('receives');

export function renderCustomAdminSection(props) {
  const {name, type, context, path} = props;

  if (
    type === 'resource' &&
    path.indexOf('/admin/book') > -1 &&
    context.position === 'middle'
  ) {
    return [
      true,
      <div style={{marginTop: 20, display: 'flex'}}>
        <AdminDownloadExcelButton
          collection="Book"
          filename="book.xlsx"
          recordsToAoa={async (records) => {
            return [
              ['標題', '作者'],
              ...records.map((record) => {
                return [record.title, record.author];
              }),
            ];
          }}
        />
        <Button
          style={{marginLeft: 10}}
          onClick={() => showAdminImportExcelModal({})}>
          匯入
        </Button>
      </div>,
    ];
  }

  if (name === 'UserCustom' && context.position === 'top') {
    return [
      true,
      <>
        <AdminUserProfileNavigation context={context} />
        <AdminUserProfileCustomActions context={context} />
        <AdminPrivateProfileCustom context={context} />
      </>,
    ];
  } else if (
    type === 'form' &&
    name === 'FormCustom' &&
    context.position === 'top'
  ) {
    return [
      true,
      <>
        <AdminFormNavigation context={context} path={path} />
        <AdminFormActions context={context} />
        <AdminFormCustomFields context={context} />
      </>,
    ];
  } else if (
    type === 'form' &&
    name === 'FormCustom' &&
    context.position === 'below-submit'
  ) {
    return [true, <AdminFormHistory context={context} />];
  } else if (name === 'FormReviewCustom' && context.position === 'top') {
    return [true, <AdminFormCustomFields context={context} />];
  } else if (name === 'FormReviewCustom' && context.position === 'bottom') {
    return [true, <AdminFormHistory context={context} />];
  } else if (name === 'FormReviewCustom' && context.position === 'below-form') {
    return [true, <AdminFormReview context={context} />];
  } else if (name === 'FormExecutionCustom' && context.position === 'top') {
    return [
      true,
      <>
        <AdminFormNavigation context={context} path={path} />
        <AdminFormActions context={context} />
        <AdminLineDivider title="基本資料" id="root_rev-line-0" />
      </>,
    ];
  } else if (
    name === 'FormExecutionCustom' &&
    context.position === 'below-form'
  ) {
    return [
      true,
      <>
        <AdminFormHistory context={context} />
        <AdminFormExecution context={context} />
      </>,
    ];
  } else if (name === 'AdminFormCustom' && context.position === 'bottom') {
    return [true, <AdminFormHistory context={context} canEdit={true} />];
  } else if (name === 'AdminSignFormCustom' && context.position === 'top') {
    const {product_id} = context.instance;
    const product = ProductsOutlet.getValue().find((p) => p.id === product_id);

    return [
      true,
      <div>
        <AdminSignFormNavigation context={context} />
        <AdminSignFormActions context={context} />
        <AdminProductBasicInfo title={'基本資料'} id={product?.id} />
        <AdminSignFormBasicInfo context={context} />
      </div>,
    ];
  } else if (name === 'MoraleCustom' && context.position === 'top') {
    return [
      true,
      <div>
        <AdminMoraleNavigation path={path} context={context} />
        <AdminMoraleCustomFields path={path} context={context} />
      </div>,
    ];
  } else if (
    name === 'MoraleCustom' &&
    context.position === 'below-form' &&
    context.instance
  ) {
    return [true, <AdminMoraleCustomActions path={path} context={context} />];
  } else if (name === 'ProductForm' && context.position === 'bottom') {
    return [true, <AdminProductCustomActions path={path} context={context} />];
  } else if (name === 'ProductForm' && context.position === 'top') {
    return [true, <AdminProductNavigation path={path} context={context} />];
  } else if (
    type === 'form' &&
    name === 'AdminOrderDetailForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminOrderNotification path={path} context={context} />
        <AdminOrderNavigation path={path} context={context} />
        <AdminOrderCustomActions path={path} context={context} />
        <AdminOrderDetailForm context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminOrderDetailForm' &&
    context.position === 'bottom'
  ) {
    return [true, null];
  } else if (
    type === 'resource' &&
    (path.indexOf('/admin/admin-morales') !== -1 ||
      path.indexOf('/admin/morales') !== -1) &&
    context.position === 'middle'
  ) {
    return [true, <AdminMoraleWiget.MoraleExtraQueries {...context} />];
  } else if (
    type === 'form' &&
    name === 'AdminNewsDetailForm' &&
    context.position === 'bottom'
  ) {
    return [true, <AdminNewsCustomActions path={path} context={context} />];
  } else if (
    type === 'form' &&
    name === 'UserCustomAttend' &&
    context.position === 'bottom'
  ) {
    return [
      true,
      <div>
        <AdminUserBasicInfo
          isUprData={true}
          user_id={context.instance.owner}
          path={path}
          context={context}
        />
        <AdminUserAttendList path={path} context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminOrderCourseForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminProductBasicInfo title="課程基本資料" context={context} />
        <AdminOrderList context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminOrderActivityForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminProductBasicInfo title="活動基本資料" context={context} />
        <AdminOrderList context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminOrderFeeForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminUserBasicInfo
          isUprData={true}
          user_id={context.instance.owner}
          path={path}
          context={context}
        />
        <AdminOrderFeeList context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminPayBillingForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminPayBillingNavigation context={context} />
        <AdminPayBillingActions context={context} />
      </div>,
    ];
  } else if (
    type === 'resource' &&
    path.indexOf('/admin/pay-billings') !== -1 &&
    context.position === 'middle'
  ) {
    return [
      true,
      <AdminPayBillingWidget.PayBillingExtraQueries {...context} />,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminStaffCustomForm' &&
    context.position === 'top'
  ) {
    return [true, <AdminStaffCustomActions context={context} />];
  } else if (
    type === 'form' &&
    name === 'AdminMeetingReadOnlyCustom' &&
    context.position === 'top'
  ) {
    return [true, <AdminMeetingNotification context={context} />];
  } else if (
    type === 'form' &&
    name === 'AdminCompanyBillingCustom' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminCompanyBillingNavigation context={context} />
        <AdminCompanyBillingActions context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminUserBillingCustom' &&
    context.position === 'top' &&
    !context.instance
  ) {
    return [
      true,
      <div>
        {context.values.user_id && (
          <AdminUserBasicInfo
            isUprData={false}
            user_id={context.values.user_id}
            path={path}
            context={context}
          />
        )}

        {context.values.price &&
          Array.isArray(context.values.discount_items) &&
          context.values.discount_items.length > 0 && (
            <AdminUserBillingDiscountItemsList path={path} context={context} />
          )}
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminUserBillingCustom' &&
    context.position === 'bottom' &&
    !context.instance
  ) {
    return [
      true,
      <div>
        {context.values.annual_year && context.values.user_id && (
          <AdminUserBillingAlert path={path} context={context} />
        )}
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminUserBillingCustom' &&
    context.position === 'below-form' &&
    !context.instance
  ) {
    return [
      true,
      <div>
        <AdminUserBillingsCreateActions context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminWelfareCustom' &&
    context.position === 'below-form'
  ) {
    return [
      true,
      <AdminWelfareBelowSection
        context={context}
        name={name}
        type={type}
        path={path}
        multilingual={path === '/admin/i18n-articles'}
      />,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminFeeNotifyBackupCustom' &&
    context.position === 'bottom'
  ) {
    return [
      true,
      <div>
        <AdminBackupEmailDataList context={context} path={path} />
      </div>,
    ];
  } else if (
    type === 'resource' &&
    path.indexOf('/admin/fee-notify-backup') > -1 &&
    context.position === 'middle'
  ) {
    return [
      true,
      <div>
        <AdminBackupEmailExtraQueries {...context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyCourseForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminLegacyCourseNavigation context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyCourseForm' &&
    context.position === 'bottom'
  ) {
    const length = (context.instance.course_session || []).length;
    return [true, <div>場次數：{length}</div>];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyActivityForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminLegacyActivityNavigation context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyECourseForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminLegacyECourseNavigation context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyReceiveForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminLegacyReceiveNavigation context={context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyPostForm' &&
    context.position === 'top'
  ) {
    return [
      true,
      <div>
        <AdminLegacyPostNavigation context={context} />
      </div>,
    ];
  } else if (
    type === 'resource' &&
    path.indexOf('/admin/receives') > -1 &&
    context.position === 'middle'
  ) {
    return [
      true,
      <div>
        <AdminReceiveAdvancedSearchButton {...context} />
      </div>,
    ];
  } else if (
    type === 'resource' &&
    path.indexOf('/admin/posts') > -1 &&
    context.position === 'middle'
  ) {
    return [
      true,
      <div>
        <AdminPostAdvancedSearchButton {...context} />
      </div>,
    ];
  } else if (
    type === 'resource' &&
    path.indexOf('/admin/products') > -1 &&
    context.position === 'middle'
  ) {
    return [
      true,
      <div>
        <AdminProductAdvancedSearchButton {...context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyReceiveForm' &&
    context.position === 'bottom'
  ) {
    return [
      true,
      <div>
        <AdminLegacyFormHistory {...context} />
      </div>,
    ];
  } else if (
    type === 'form' &&
    name === 'AdminLegacyPostForm' &&
    context.position === 'bottom'
  ) {
    return [
      true,
      <div>
        <AdminLegacyFormHistory {...context} />
      </div>,
    ];
  }

  return [false, null];
}

export function renderCustomAdminCol(props) {
  const {col, record, path} = props;

  if (col.customType === 'user_state') {
    const display = getUserStateLabel(record[col.key]);
    return [true, <div>{display}</div>];
  } else if (col.customType === 'user_substate') {
    const display = getUserSubstateLabel(record[col.key]);
    return [true, <div>{display}</div>];
  } else if (col.customType === 'user_profile') {
    const display = userPendingJson.schema.required.every((key) => {
      if (key === 'public') {
        return typeof record[key] === 'boolean';
      }
      return !!record[key];
    })
      ? '填寫完成'
      : '尚未填寫';

    return [true, <Tag>{display}</Tag>];
  } else if (col.customType === 'user_checkout') {
    const display =
      record[col.key] === 'pending_review' ? '完成繳費' : '尚未繳費';
    return [true, <Tag>{display}</Tag>];
  } else if (col.customType === 'string_boolean') {
    const display =
      record[col.key] === 'Y' || record[col.key] === '1' ? '是' : '否';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'sex') {
    const display = record[col.key] === 'M' ? '男' : '女';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'room') {
    const display = getMeetingRoomLabel(record[col.key], record.room_note);
    return [true, <div>{display}</div>];
  } else if (col.customType === 'is_open') {
    const display = record[col.key] === '0' ? '否' : '是';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'committee_category') {
    const display = (() => {
      switch (record[col.key]) {
        case 'GA':
          return '全聯會代表';
        case 'BDS':
          return '理監事名單';
        case 'SG':
          return '秘書處名單';
        case 'STC':
          return '專門委員會';
        case 'SFC':
          return '專業領域委員會';
        case 'SUBGRP':
          return '社團';
        case 'GRP':
          return '群組';
        case 'NL':
          return '名單';
        case 'SC':
          return '特別委員會';
        default:
          return record[col.key];
      }
    })();
    return [true, <div>{display}</div>];
  } else if (col.customType === 'committee_users') {
    const display =
      record[col.key] && Array.isArray(record[col.key])
        ? record[col.key].length
        : record['members'] && Array.isArray(record['members'])
        ? record['members'].length
        : 0;
    return [true, <div>{display}</div>];
  } else if (col.customType === 'news_type') {
    const display = record[col.key] === 'news_paper' ? '新聞稿' : '重大訊息';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'date_string') {
    const display =
      formatDateStr(new Date(record[col.key])) !== 'Invalid Date'
        ? formatDateStr(new Date(record[col.key]))
        : '';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'Create_Time') {
    const display = record.created
      ? formatDateTimeStr(new Date(record.created))
      : formatDateTimeStr(new Date(record[col.key]));
    return [true, <div>{display}</div>];
  } else if (
    col.customType === 'Modify_Time' ||
    col.customType === 'UPDATE_TIME'
  ) {
    const display = record.updated
      ? formatDateTimeStr(new Date(record.updated))
      : formatDateTimeStr(new Date(record[col.key]));
    return [true, <div>{display}</div>];
  } else if (col.customType === 'MTNG_TYPE') {
    const display = (() => {
      switch (record[col.key]) {
        case 'INNER':
          return '內部';
        case 'OUTER':
          return '外部';
        case 'GSM':
          return '會員大會';
        case 'BM':
          return '理事會';
        case 'SV':
          return '監事會';
        case 'MD':
          return '常務理事會議';
        case 'BOSM':
          return '理監事聯席會議';
        default:
          return record[col.key];
      }
    })();
    return [true, <div>{display}</div>];
  } else if (col.customType === 'MTNG_NAME') {
    const display = record[col.key];
    const url = record.is_auto_created
      ? `/admin/meetings_readonly/?action=detail&id=${record.id}`
      : `/admin/meetings/?action=detail&id=${record.id}`;

    return [true, <AdminLinkButton title={display} url={url} />];
  } else if (col.customType === 'download_type') {
    const display = (() => {
      switch (record[col.key]) {
        case 1:
          return '一般下載';
        case 2:
          return '會員下載中心';
        case 3:
          return '一般下載-申請書表';
        case 4:
          return '一般下載-申請書表-申請加入會員入會表格';
        case 5:
          return '一般下載-申請書表-申請加入準會員入會表格';
        case 6:
          return '一般下載-申請書表-一般表格';
        case 7:
          return '會員下載中心-相關資訊';
        case 8:
          return '會員下載中心-申請書表';
        case 9:
          return '會員下載中心-律師用紙';
        case 10:
          return '會員下載中心-相關資訊';
        case 11:
          return '會員下載中心-電子書';
        default:
          return record[col.key];
      }
    })();
    return [true, <Tag>{display}</Tag>];
  } else if (col.customType === 'form_stage') {
    const display = getFormStageLabel(record[col.key]);
    const color = getFormStageLabelColor(record[col.key]);

    return [true, <Tag color={color}>{display}</Tag>];
  } else if (col.customType === 'form_type_review') {
    const title = getFormTypeLabel(record[col.key]);
    const url = `/admin/reviews/?action=detail&id=${record.id}&type=${record.type}`;
    return [true, <AdminLinkButton title={title} url={url} />];
  } else if (col.customType === 'form_type_readonly') {
    const title = getFormTypeLabel(record[col.key]);
    return [true, <div>{title}</div>];
  } else if (col.customType === 'form_related_info') {
    return [
      true,
      <div style={{maxWidth: col.width, width: '100%'}}>
        {record.type === 'post' && <div>受文者：{record.addressee}</div>}
        {record.type === 'receive' && <div>發文機關：{record.organs}</div>}
        <div>主旨：{record.title}</div>
      </div>,
    ];
  } else if (col.customType === 'review_status') {
    const display = getCompanyReviewStatusLabel(record[col.key]);
    const color = getCompanyReviewStatusLabelColor(record[col.key]);

    return [true, <Tag color={color}>{display}</Tag>];
  } else if (col.customType === 'form_type_execution') {
    const title = getFormTypeLabel(record[col.key]);
    const url = `/admin/executions/?action=detail&id=${record.id}&type=${record.type}`;
    return [true, <AdminLinkButton title={title} url={url} />];
  } else if (col.customType === 'form_data_execution') {
    const display =
      record.type === 'post' ? (
        <p>
          <label>受文者：</label>
          <span>{record.addressee || '未填寫'}</span>
        </p>
      ) : (
        <p>
          <label>發文機關：</label>
          <span>{record.organs || '未填寫'}</span>
        </p>
      );
    return [true, <div>{display}</div>];
  } else if (col.customType === 'form_type_counter') {
    const title = `${getFormTypeLabel(record[col.key])}編號設定`;
    const url = `/admin/form-counter/?action=detail&id=${record.id}`;
    return [true, <AdminLinkButton title={title} url={url} />];
  } else if (col.customType === 'form_counter_idx') {
    const display = (() => {
      if (record.collection === 'post') {
        return (
          <>
            <div>{record[col.key]}</div>
            <div style={{color: 'var(--secondColor)'}}>系統自動加 4000</div>
          </>
        );
      }
      return record[col.key];
    })();
    return [true, <div>{display}</div>];
  } else if (col.customType === 'download') {
    const url = record[col.key];
    return [
      true,
      <Button icon={<Download size={20} />} type="link" href={url} />,
    ];
  } else if (col.customType === 'edit-internal-message-content') {
    return [
      true,
      <Button
        onClick={() => {
          getOutlet('AdminMessageEditorModal').update(record);
        }}>
        編輯內容
      </Button>,
    ];
  } else if (col.customType === 'address') {
    const zip_code = record['address_zip_code'] || '';
    const city = record['address_city']
      ? record['address_city'].replace('-', '')
      : '';
    const detail = record['address_detail'];

    const display = `${zip_code} ${city}${detail}`;

    return [
      true,
      <div style={{maxWidth: col.width, width: '100%'}}>{display}</div>,
    ];
  } else if (col.customType === 'company_billing_group_users') {
    let users = [];

    for (let item of record[col.key]) {
      const index = users.findIndex((user) => user === item.user);

      if (index === -1) {
        users.push(item.user);
      }
    }

    const display = users.length;

    return [true, <div>{display}</div>];
  } else if (col.customType === 'company_billing_group_length') {
    const display = record[col.key].length;
    return [true, <div>{display}</div>];
  } else if (col.customType === 'company_billing_total') {
    let display = 0;

    if (record.group && record.group.length > 0) {
      display = record.group.reduce((acc, cur) => acc + cur.price, 0);
    }

    return [true, <div>{display}</div>];
  } else if (col.customType === 'user_type') {
    const display = getUserTypeLabel(record[col.key]);
    return [true, <div>{display}</div>];
  } else if (col.customType === 'user_status') {
    const display = getUserStatusLabel(record[col.key]);
    return [true, <div>{display}</div>];
  } else if (col.customType === 'company_billing_company') {
    const company = record.company_detail;
    const display = company ? company.CONAME : null;
    const id = company ? company.id : null;
    const meta_data = company ? (
      <div style={{paddingLeft: 8}}>
        <div>
          地址：{company.address_zip_code}
          {company.address_city}
          {company.address_detail}
        </div>
        <div>電話：{company.COPHONE1 || '無'}</div>
        <div>傳真：{company.COFAX1 || '無'}</div>
      </div>
    ) : null;

    return [
      true,
      <div style={{maxWidth: col.width || 220, width: '100%'}}>
        <AdminLinkButton
          style={{paddingLeft: 8}}
          title={display}
          url={`/admin/companies/?action=detail&id=${id}`}
        />
        {meta_data}
      </div>,
    ];
  } else if (col.customType === 'company_current_name') {
    const company = record.company_current_detail;
    const display = company ? company.CONAME : null;
    const id = company ? company.id : null;
    const url = `/admin/companies/?action=detail&id=${id}`;

    return [
      true,
      display ? (
        <div>
          <div style={{marginBottom: 4}}>{display}</div>
          <Button
            type="link"
            href={url}
            target="_blank"
            size="small"
            icon={<ExportOutlined />}
            style={{
              border: '1px solid var(--primaryColor)',
              borderRadius: '50%',
            }}
          />
        </div>
      ) : null,
    ];
  } else if (col.customType === 'company_current_address') {
    const company = record.company_current_detail;
    const display = company ? (
      <div>
        {record.company_zip_code || company.address_zip_code}{' '}
        {record.company_city?.replace('-', '') ||
          company.address_city?.replace('-', '')}
        {record.company_detail || company.address_detail}
      </div>
    ) : null;
    return [true, <div>{display}</div>];
  } else if (col.customType === 'company_current_fax') {
    const company = record.company_current_detail;
    const display = company ? (
      <div>
        <div>{record.company_fax || company.COFAX1 || '無'}</div>
      </div>
    ) : null;
    return [true, <div>{display}</div>];
  } else if (col.customType === 'company_current_phone') {
    const company = record.company_current_detail;
    const display = company ? (
      <div>{record.company_phone || company.COPHONE1 || '無'}</div>
    ) : null;
    return [true, <div>{display}</div>];
  } else if (col.customType === 'company_data') {
    const company = record.company_current_detail;
    const id = company ? company.id : null;

    const display = company ? (
      <AdminLinkButton
        title={company.CONAME}
        url={`/admin/companies/?action=detail&id=${id}`}
      />
    ) : (
      <div style={{paddingLeft: 16}}>
        <div>{record.company_name}</div>
        <div>電話：{record.company_tel}</div>
        <div>傳真：{record.company_fax}</div>
        <div>{record.company_email}</div>
        <div>{record.company_address}</div>
      </div>
    );

    return [true, <div>{display}</div>];
  } else if (col.customType === 'users') {
    const displays = (() => {
      const nextValue = [];

      if (record[col.key]) {
        for (const owner of record[col.key]) {
          const upr = UsersOutlet.getValue().find(
            (user) => user.owner === owner,
          );
          nextValue.push(upr?.name);
        }
      }

      return nextValue;
    })();

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {displays.map((display) => (
          <div>{display}</div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'array_of_object_users') {
    const displays = (() => {
      const nextValue = [];

      if (record[col.key]) {
        for (const r of record[col.key]) {
          const upr = UsersOutlet.getValue().find(
            (user) => user.owner === r.user,
          );
          nextValue.push(upr?.name);
        }
      }

      return nextValue;
    })();

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {displays.map((display) => (
          <div>{display}</div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'staff') {
    const display = (() => {
      const nextValue = (StaffsOutlet.getValue() || {}).find(
        (staff) => staff.id === record[col.key],
      );

      return nextValue?.name || '';
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'admin_morale_case_number') {
    const title = record[col.key];
    const url = `/admin/admin-morales/?action=detail&id=${record.id}`;
    return [true, <AdminLinkButton title={title} url={url} />];
  } else if (col.customType === 'morale_status') {
    const display = (() => {
      if (record[col.key] === '其他') {
        return `其他 ${record.status_note || ''}`;
      }

      return record[col.key];
    })();

    return [true, <div style={{width: col.width || 'auto'}}>{display}</div>];
  } else if (col.customType === 'morale_public') {
    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {record.public ? <Tag color="green">已審核</Tag> : <Tag>未審核</Tag>}
      </div>,
    ];
  } else if (col.customType === 'morale_occur_date') {
    const isFinished = record && record.status === '已結案';
    const isOccurDateOverThreeYears =
      record &&
      record.occur_date &&
      new Date().getTime() >=
        new Date(record.occur_date).getTime() + 365 * 3 * 24 * 60 * 60 * 1000;
    const isOccurDateOverSevenYears =
      record &&
      record.occur_date &&
      new Date().getTime() >=
        new Date(record.occur_date).getTime() + 365 * 7 * 24 * 60 * 60 * 1000;

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {(isOccurDateOverThreeYears || isOccurDateOverSevenYears) &&
          !isFinished && (
            <Tag color="gold" style={{marginBottom: 10}}>
              已超過{isOccurDateOverSevenYears ? '七' : '三'}年
            </Tag>
          )}
        <span>{record[col.key]}</span>
      </div>,
    ];
  } else if (col.customType === 'morale_results') {
    const displays = (() => {
      let nextValue = [];

      if (record[col.key]) {
        for (const r of record[col.key]) {
          const upr = UsersOutlet.getValue().find(
            (user) => user.owner === r.user,
          );

          nextValue.push({name: upr?.name, result: r.result});
        }
      }

      if (record['results_tba_normal']) {
        const nonUprs = record['results_tba_normal'].map((nonUpr) => ({
          name: nonUpr.name,
          result: nonUpr.result,
        }));
        nextValue = [...nextValue, ...nonUprs];
      }

      return nextValue;
    })();

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {displays.map((display) => (
          <div>
            {display.name} - {display.result}
          </div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'morale_prosecutors') {
    const displays = (() => {
      let nextValue = [];

      if (record[col.key]) {
        for (const owner of record[col.key]) {
          const upr = UsersOutlet.getValue().find(
            (user) => user.owner === owner,
          );
          nextValue.push(upr?.name);
        }
      }

      if (record['prosecutors_normal']) {
        const nonUprs = record['prosecutors_normal'].map(
          (nonUpr) => nonUpr.name,
        );
        nextValue = [...nextValue, ...nonUprs];
      }

      return nextValue;
    })();

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {displays.map((display) => (
          <div>{display}</div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'morale_defendants') {
    const displays = (() => {
      let nextValue = [];

      if (record[col.key]) {
        for (const owner of record[col.key]) {
          const upr = UsersOutlet.getValue().find(
            (user) => user.owner === owner,
          );
          nextValue.push(upr?.name);
        }
      }

      if (record['defendants_normal']) {
        const nonUprs = record['defendants_normal'].map(
          (nonUpr) => nonUpr.name,
        );
        nextValue = [...nextValue, ...nonUprs];
      }

      return nextValue;
    })();
    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {displays.map((display) => (
          <div>{display}</div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'morale_case_number') {
    const title = `第${record.case_number}號`;
    const url = `/admin/morales/?action=detail&id=${record.id}`;

    return [
      true,
      <div>
        <span>{record.case_number_prefix}</span>
        <AdminLinkButton
          title={title}
          url={url}
          style={{
            width: col.width || 'auto',
            padding: 0,
            textAlign: 'left',
            fontSize: 14,
          }}
        />
      </div>,
    ];
  } else if (col.customType === 'tba-send-push') {
    return [
      true,
      <Popconfirm
        title={`確認發送嗎？`}
        okText="確認"
        cancelText="取消"
        onConfirm={async () => {
          AppActions.setLoading(true);
          try {
            if (Array.isArray(record.users) && record.users.length > 0) {
              // pointer users
              for (let i = 0; i < record.users.length; i++) {
                await CustomAppActions.adminSendPush({
                  id: record.id,
                  user_id: record.users[i],
                });
              }
            } else {
              // all users
              await CustomAppActions.adminSendPush({id: record.id});
            }

            message.success('發送成功');
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          }
          AppActions.setLoading(false);
        }}>
        <Button type="primary">發送推播</Button>
      </Popconfirm>,
    ];
  } else if (col.customType === 'live_stream_product_session') {
    const targetProduct = ProductsOutlet.getValue().find(
      (product) => product.id === record.product,
    );

    const isSession =
      targetProduct &&
      targetProduct.session &&
      Object.keys(targetProduct.session).length > 0 &&
      targetProduct.session.date;

    const display = isSession ? `${targetProduct.session.date}` : '---';

    return [
      true,
      <div>
        <AdminLinkButton
          title={display}
          url={`/admin/live_streams/?action=detail&id=${record.id}`}
        />
        {isSession && (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            {targetProduct.session.start_time}-{targetProduct.session.end_time}
          </div>
        )}
      </div>,
    ];
  } else if (col.customType === 'live_stream_console') {
    return [
      true,
      <AdminLinkButton
        title="開啟直播"
        url={`/admin/live_stream_console?id=${record.id}`}
      />,
    ];
  } else if (col.customType === 'mux_permission') {
    const display = record[col.key] === 'public' ? '公開' : '專屬';
    const color = record[col.key] === 'public' ? '#402E7A' : '#677D6A';

    return [
      true,
      <div>
        <Tag color={color}>{display}</Tag>
      </div>,
    ];
  } else if (col.customType === 'live_stream_status') {
    const display = record[col.key] === 'on' ? '已開啟' : '已關閉';
    const color = record[col.key] === 'on' ? '#ff4d4f' : '#000000';

    return [true, <div style={{color}}>{display}</div>];
  } else if (col.customType === 'product') {
    return [
      true,
      <AdminLinkButton
        title="查看詳細"
        url={`/admin/products/?action=detail&id=${record.product}`}
      />,
    ];
  } else if (col.customType === 'receives_related') {
    const displays = (() => {
      const nextValue = [];

      if (record[col.key]) {
        for (const id of record[col.key]) {
          const _receive = ReceivesOutlet.getValue().find((_r) => _r.id === id);

          if (_receive) {
            nextValue.push({id, paperwork_id: _receive.paperwork_id});
          }
        }
      }

      return nextValue;
    })();

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        {displays.map((display) => (
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={`/admin/receives/?action=detail&id=${display.id}`}>
              {display.paperwork_id}
            </a>
          </div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'members_total') {
    const display = (() => {
      if (record[col.key]) {
        return record[col.key].length;
      }

      return 0;
    })();

    return [true, <div style={{width: col.width || 'auto'}}>{display}人</div>];
  } else if (col.customType === 'members_attend') {
    const display = (() => {
      const nextValue = [];

      if (record[col.key]) {
        for (const r of record[col.key]) {
          const isAttend = r.is_attend;

          if (isAttend) {
            nextValue.push(r);
          }
        }

        return nextValue.length;
      }

      return 0;
    })();

    return [true, <div style={{width: col.width || 'auto'}}>{display}人</div>];
  } else if (col.customType === 'signform_session') {
    const targetProduct = (ProductsOutlet.getValue() || {}).find(
      (product) => product.id === record.product_id,
    );

    const isSession =
      targetProduct &&
      targetProduct.session &&
      Object.keys(targetProduct.session).length > 0 &&
      targetProduct.session.date;

    const display = isSession ? `${targetProduct.session.date}` : '---';

    return [
      true,
      <div>
        <AdminLinkButton
          title={display}
          url={`/admin/sign_forms/?action=detail&id=${record[col.key]}`}
        />
        {isSession && (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            {targetProduct.session.start_time}-{targetProduct.session.end_time}
          </div>
        )}
      </div>,
    ];
  } else if (col.customType === 'signform_name') {
    const display = (() => {
      const nextValue = (ProductsOutlet.getValue() || {}).find(
        (product) => product.id === record.product_id,
      );

      return nextValue?.name || record[col.key] || record.id;
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'is_posted_hours') {
    const display = (() => {
      return !!record[col.key] ? '已發放' : '未發放';
    })();
    const tagColor = !!record[col.key] ? 'gold' : 'purple';

    return [true, <Tag color={tagColor}>{display}</Tag>];
  } else if (col.customType === 'post_link') {
    const display = record.is_old ? '# 舊' : record[col.key];
    const url = record.is_old
      ? `/admin/legacy-posts/?action=detail&id=${record.id}`
      : `/admin/posts/?action=detail&id=${record.id}&stage=${record.stage}`;

    return [
      true,
      <div style={{padding: '0px 5px'}}>
        <a
          href={url}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(url);
          }}>
          {display}
        </a>
      </div>,
    ];
  } else if (col.customType === 'post_text_number') {
    const display = record.is_old ? record['MISSIVE_NO'] : record[col.key];
    return [true, <div>{display}</div>];
  } else if (col.customType === 'post_date') {
    const display = record.is_old
      ? record['ISSUE_DATE']?.split('T')[0] || '---'
      : record[col.key] || '---';
    return [true, <div style={{width: col.width || 'auto'}}>{display}</div>];
  } else if (col.customType === 'post_addressee') {
    const display = record.is_old
      ? record['ORIGINAL_TO']
      : record[col.key] || '---';
    return [true, <div style={{width: col.width || 'auto'}}>{display}</div>];
  } else if (col.customType === 'post_category') {
    const display = record.is_old
      ? getLegacyPostCategoryLabel(record['FORM_TYPE'])
      : record[col.key] || '---';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'post_title') {
    const display = record.is_old
      ? record['DRIFT']
        ? record['DRIFT'].length > 20
          ? `${record['DRIFT'].slice(0, 20)}...`
          : record['DRIFT']
        : '---'
      : record[col.key]
      ? record[col.key].length > 20
        ? `${record[col.key].slice(0, 20)}...`
        : record[col.key]
      : '---';

    const title = record.is_old
      ? record['DRIFT'] || '---'
      : record[col.key] || '---';

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        <div title={title}>{display}</div>
      </div>,
    ];
  } else if (col.customType === 'post_stage') {
    const display = record.is_old
      ? getLegacyFormProgressLabel(record['PROGRESS'])
      : getFormStageLabel(record[col.key]);
    const color = getFormStageLabelColor(record[col.key]);

    return [true, <Tag color={color}>{display}</Tag>];
  } else if (col.customType === 'post_implement') {
    const display = (() => {
      const nextValue = record.is_old
        ? record.PROC_BY?.NAME
        : (StaffsOutlet.getValue() || {}).find(
            (staff) => staff.id === record[col.key],
          )?.name;

      return nextValue || '---';
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'post_author_name') {
    const display = record.is_old ? record['CREATE_BY']?.NAME : record[col.key];
    return [true, <div>{display}</div>];
  } else if (col.customType === 'post_executor') {
    const display = record.is_old
      ? Array.isArray(record.implements) && record.implements.length > 0
        ? record.implements[record.implements.length - 1]?.DO_USER.NAME || '---'
        : '---'
      : getStaffValue(record[col.key], 'name');
    return [true, <div>{display}</div>];
  } else if (col.customType === 'receive_link') {
    const display = record.is_old
      ? `${record['SERIAL_NO']} # 舊` || '---'
      : record[col.key] || '---';

    const url = record.is_old
      ? `/admin/legacy-receives/?action=detail&id=${record.id}`
      : `/admin/receives/?action=detail&id=${record.id}&stage=${record.stage}`;

    return [
      true,
      <div style={{padding: '0px 5px'}}>
        <a
          href={url}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(url);
          }}>
          {display}
        </a>
      </div>,
    ];
  } else if (col.customType === 'receive_date') {
    const display = record.is_old
      ? record['RECEIVE_DATE']
        ? record['RECEIVE_DATE'].split('T')[0]
        : '---'
      : record[col.key] || '---';

    return [true, <div>{display}</div>];
  } else if (col.customType === 'receive_organs') {
    const display = record.is_old
      ? record['FROM_ORG'] || '---'
      : record[col.key] || '---';

    return [true, <div style={{width: col.width || 'auto'}}>{display}</div>];
  } else if (col.customType === 'receive_category') {
    const display = record.is_old
      ? getLegacyReceiveCategoryLabel(record['FORM_TYPE']) || '---'
      : record[col.key] || '---';

    return [true, <div>{display}</div>];
  } else if (col.customType === 'receive_text_number') {
    const display = record.is_old
      ? record['WORD_NO'] || '---'
      : record[col.key] || '---';

    return [true, <div style={{width: col.warn || 'auto'}}>{display}</div>];
  } else if (col.customType === 'receive_title') {
    const display = record.is_old
      ? record['DRIFT']
        ? record['DRIFT'].length > 20
          ? `${record['DRIFT'].slice(0, 20)}...`
          : record['DRIFT']
        : '---'
      : record[col.key]
      ? record[col.key].length > 20
        ? `${record[col.key].slice(0, 20)}...`
        : record[col.key]
      : '---';

    const title = record.is_old
      ? record['DRIFT'] || '---'
      : record[col.key] || '---';

    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        <div title={title}>{display}</div>
      </div>,
    ];
  } else if (col.customType === 'receive_stage') {
    const display = record.is_old
      ? getLegacyFormProgressLabel(record['PROGRESS'])
      : getFormStageLabel(record[col.key]);
    const color = getFormStageLabelColor(record[col.key]);

    return [true, <Tag color={color}>{display}</Tag>];
  } else if (col.customType === 'receive_implement') {
    const display = (() => {
      const nextValue = record.is_old
        ? record.PROC_BY?.NAME
        : (StaffsOutlet.getValue() || {}).find(
            (staff) => staff.id === record[col.key],
          )?.name;

      return nextValue || '---';
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'receive_author_name') {
    const display = record.is_old ? record['CREATE_BY']?.NAME : record[col.key];
    return [true, <div>{display}</div>];
  } else if (col.customType === 'receive_executor') {
    const display = record.is_old
      ? Array.isArray(record.implements) && record.implements.length > 0
        ? record.implements[record.implements.length - 1]?.DO_USER.NAME || '---'
        : '---'
      : getStaffValue(record[col.key], 'name');
    return [true, <div>{display}</div>];
  } else if (col.customType === 'execution') {
    const displays = (() => {
      const nextValue = [];

      if (record[col.key]) {
        for (const r of record[col.key]) {
          const upr = StaffsOutlet.getValue().find(
            (staff) => staff.id === r.user_id,
          );
          nextValue.push(upr?.name);
        }
      }

      return nextValue;
    })();

    return [
      true,
      <div>
        {displays.map((display) => (
          <div style={{width: record[col.key].width}}>{display}</div>
        ))}
      </div>,
    ];
  } else if (col.customType === 'party_checkin') {
    return [
      true,
      <AdminLinkButton
        title="開啟"
        disabled={!record.import_staff_name || !record.import_timestamp}
        url={`/admin/party_checkin?id=${record.id}`}
      />,
    ];
  } else if (col.customType === 'party_import') {
    const display =
      record.import_staff_name && record.import_timestamp ? (
        <div>
          <div>匯入人員：{record.import_staff_name}</div>
          <div>
            匯入時間：
            {new Date(record.import_timestamp).toLocaleDateString('sv')}
          </div>
        </div>
      ) : (
        <Popconfirm
          title={'確認匯入會員名單？'}
          okText="確認"
          cancelText="取消"
          onConfirm={async () => {
            message.info('會員名單匯入中，請耐心等候');

            try {
              AppActions.setLoading(true);

              const targetUsers = await JStorage.fetchAllDocuments(
                'user_profile',
                {
                  state: 1,
                  substate: {$in: ['4', '5', '6']},
                },
                null,
                {
                  owner: 1,
                  name: 1,
                  LAWYER_NO: 1,
                  id_card_number: 1,
                  substate: 1,
                  phone: 1,
                },
              );

              const nextUsers = [...targetUsers]
                .map((user) => ({
                  name: user.name,
                  user_id: user.owner,
                  LAWYER_NO: user.LAWYER_NO,
                  state: user.state,
                  substate: user.substate,
                  phone: user.phone,
                  id_card_number: user.id_card_number,
                }))
                .sort((a, b) => a.name?.localeCompare(b.name, 'zh-Hant'));

              await JStorage.updateDocument(
                'party',
                {id: record.id},
                {
                  users: nextUsers,
                  extras: [],
                  import_staff: UserOutlet.getValue().sub,
                  import_staff_name: UserOutlet.getValue().name,
                  import_timestamp: new Date().getTime(),
                },
              );

              message.success('匯入完成');
              AppActions.navigate(`/admin/party_checkin/?id=${record.id}`);
            } catch (err) {
              console.warn(err);
              message.error('發生錯誤');
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          <Button>匯入</Button>
        </Popconfirm>
      );

    return [true, <div>{display}</div>];
  } else if (col.customType === 'MTNG_NAME-to-meeting') {
    return [
      true,
      <Button
        type="link"
        onClick={() =>
          window.open(`/admin/meetings/?action=detail&id=${record.id}`)
        }>
        {record.MTNG_NAME}
      </Button>,
    ];
  } else if (col.customType === 'paperwork_id-to-form') {
    return [
      true,
      <Button
        type="link"
        onClick={() => {
          if (record.type === 'receive') {
            window.open(`/admin/receives/?action=detail&id=${record.id}`);
          } else if (record.type === 'post') {
            window.open(`/admin/posts/?action=detail&id=${record.id}`);
          }
        }}>
        {record.paperwork_id}
      </Button>,
    ];
  } else if (col.customType === 'meeting-copy') {
    return [
      true,
      <Button
        onClick={async () => {
          AppActions.setLoading(true);
          try {
            let recordCopy = {...record};
            delete recordCopy.id;
            delete recordCopy._id;
            recordCopy = {
              ...recordCopy,
              MTNG_NAME: `${recordCopy.MTNG_NAME}__copy`,
            };
            message.info('創建中...');
            await JStorage.createDocument('Meeting', recordCopy);
            window.location.reload();
          } catch (ex) {
            console.warn(ex);
            message.error('API fail, 請稍後再試');
          }
          AppActions.setLoading(false);
        }}>
        複製
      </Button>,
    ];
  } else if (col.customType === 'withdraw_payment_status') {
    const display = (() => {
      switch (record[col.key]) {
        case 'review':
          return '尚未審核';
        case 'pending':
          return '尚未付款';
        case 'success':
          return '付款成功';
        default:
          return record[col.key];
      }
    })();
    return [true, <Tag>{display}</Tag>];
  } else if (col.customType === 'executor') {
    const display = getStaffValue(record[col.key], 'name');
    return [true, <div>{display}</div>];
  } else if (col.customType === 'text_ellipsis') {
    const display = record[col.key]
      ? record[col.key].length >= 20
        ? `${record[col.key].slice(0, 20)}...`
        : record[col.key]
      : '';
    return [true, <div title={record[col.key]}>{display}</div>];
  } else if (col.customType === 'inout_leave_reason') {
    const display = getUserInoutLeaveReasonLabel(record[col.key]);
    return [true, <div>{display}</div>];
  } else if (col.customType === 'order_annual_year') {
    const display = `${record[col.key]}`;
    let url = OrderUtil.getAdminUrlFromMergedCheckout(record);
    return [
      true,
      <AdminLinkButton
        title={display}
        url={`${url}&backPath=/admin/user-billings`}
      />,
    ];
  } else if (col.customType === 'return_info') {
    const targetUser = UsersOutlet.getValue().find(
      (u) => u.owner === record['owner'],
    );

    const user_display = targetUser ? targetUser.name : '';
    const user_id = targetUser ? targetUser.id : '';

    const targetProduct = record.items[0];
    const product_display = targetProduct ? targetProduct.name : '';
    const product_id = targetProduct ? targetProduct.product.id.$oid : '';
    const product_session =
      targetProduct &&
      targetProduct.product.session &&
      targetProduct.product.session.date &&
      targetProduct.product.session.start_time &&
      targetProduct.product.session.end_time
        ? `${targetProduct.product.session.date} ${targetProduct.product.session.start_time} - ${targetProduct.product.session.end_time}`
        : '';

    return [
      true,
      <div>
        <div>
          <AdminLinkButton
            title={user_display}
            url={`/admin/users/?action=detail&id=${user_id}`}
            style={{padding: 0}}
          />
          <span> / </span>
          <AdminLinkButton
            title={product_display}
            url={`/admin/products/?action=detail&id=${product_id}`}
            style={{padding: 0}}
          />
        </div>
        <div>{product_session}</div>
        <div>
          訂單編號：
          <AdminLinkButton
            title={record.order_id || '訂單詳情'}
            url={`/admin/order/?action=detail&id=${record.order}`}
            style={{padding: 0}}
          />
          <span style={{paddingLeft: 5, paddingRight: 5}}>${record.total}</span>
        </div>
        <div>取消報名原因：{record.data.reason}</div>
      </div>,
    ];
  } else if (col.customType === 'user_attend_username') {
    const display = record[col.key];
    const url = `/admin/users/?action=detail&id=${record.id}&backPath=/admin/users-attend-record`;

    return [true, <AdminLinkButton title={display} url={url} />];
  } else if (col.customType === 'user_attend_legacy_data_entry') {
    const url = `/admin/users-attend-record/?action=detail&id=${record.id}`;
    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        <AdminLinkButton
          title="112年前會費紀錄"
          url={`${url}&isLegacy=true&tab=fee`}
        />
        <AdminLinkButton
          title="112年前參與課程紀錄"
          url={`${url}&isLegacy=true&tab=courses`}
        />
        <AdminLinkButton
          title="112年前參與活動紀錄"
          url={`${url}&isLegacy=true&tab=activities`}
        />
        <AdminLinkButton
          title="112年前參與會議紀錄"
          url={`${url}&isLegacy=true&tab=meetings`}
        />
        <AdminLinkButton
          title="112年前授課紀錄"
          url={`${url}&isLegacy=true&tab=teaches`}
        />
      </div>,
    ];
  } else if (col.customType === 'user_attend_data_entry') {
    const url = `/admin/users-attend-record/?action=detail&id=${record.id}`;
    return [
      true,
      <div style={{width: col.width || 'auto'}}>
        <AdminLinkButton title="參與課程紀錄" url={`${url}&tab=courses`} />
        <AdminLinkButton title="參與活動紀錄" url={`${url}&tab=activities`} />
        <AdminLinkButton title="參與會議紀錄" url={`${url}&tab=meetings`} />
        <AdminLinkButton title="授課紀錄" url={`${url}&tab=lectures`} />
      </div>,
    ];
  } else if (col.customType === 'product_session') {
    const isSession =
      record.session &&
      Object.keys(record.session).length > 0 &&
      record.session.date;

    const display = isSession
      ? `${record.session.date} ${
          record.session.start_time ? `${record.session.start_time}` : ``
        }`
      : '---';

    return [
      true,
      <div style={{maxWidth: col['width'], width: '100%'}}>{display}</div>,
    ];
  } else if (col.customType === 'pay_info') {
    return [
      true,
      <div>
        <div>{record.content}</div>
        <div>
          通訊：{record.mail_address_zip_code} {record.mail_address_city}
          {record.mail_address_detail}
        </div>
        <div>
          寄送：{record.send_address_zip_code} {record.send_address_city}
          {record.send_address_detail}
        </div>
      </div>,
    ];
  } else if (col.customType === 'payment_subtype') {
    return [
      true,
      <div>{Cart.PAYMENT_SUBTYPE_DISPLAY[record[col.key]]?.label}</div>,
    ];
  } else if (col.customType === 'order_type') {
    const itemName = record.items[0].product.name;
    let type = {};

    if (itemName.indexOf('入會費') > -1) {
      type = {value: 'infee', label: '入會費'};
    } else if (itemName.indexOf('常年會費') > -1) {
      type = {value: 'fee', label: '常年會費'};
    } else if (itemName.indexOf('跨區會費') > -1) {
      type = {value: 'crossfee', label: '跨區會費'};
    }

    let display = type.label;
    let url = OrderUtil.getAdminUrlFromMergedCheckout(record);

    return [
      true,
      <AdminLinkButton
        title={display}
        url={`${url}&backPath=/admin/orders-refund`}
      />,
    ];
  } else if (col.customType === 'refund_info') {
    const itemName = record.items[0].product.name;
    let type = {};

    if (itemName.indexOf('入會費') > -1) {
      type = {value: 'infee', label: '入會費'};
    } else if (itemName.indexOf('常年會費') > -1) {
      type = {value: 'fee', label: '常年會費'};
    } else if (itemName.indexOf('跨區會費') > -1) {
      type = {value: 'crossfee', label: '跨區會費'};
    }

    const display = (
      <div>
        <div>退款日期：{record.refund_date}</div>
        <div>{itemName}</div>
        {type.value === 'fee' && (
          <div>
            {record.annual_year} 年度 {record.start_month}月 -{' '}
            {record.end_month}月
          </div>
        )}
        {type.value === 'crossfee' && (
          <div>
            {record.items[0].config.extra_data?.start_date} -{' '}
            {record.items[0].config.extra_data?.end_date}
          </div>
        )}
      </div>
    );

    return [true, <div>{display}</div>];
  } else if (col.customType === 'welfare_labels') {
    const display = (() => {
      return (record[col.key] || []).map((label) => (
        <div style={{marginBottom: 5}}>
          <Tag>{label}</Tag>
        </div>
      ));
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'live_stream_points') {
    const display = (() => {
      const targetProduct = ProductsOutlet.getValue().find(
        (product) => product.id === record.product,
      );

      if (!record.product || !targetProduct) {
        return null;
      }

      return <div>{targetProduct.tba_hours} 小時</div>;
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'live_stream_points_publish') {
    const display = (() => {
      const targetProduct = ProductsOutlet.getValue().find(
        (product) => product.id === record.product,
      );

      if (!record.product || !targetProduct) {
        return null;
      }

      return targetProduct.tba_hours > 0 ? (
        <div>
          {targetProduct.non_publish_hour ? (
            <div>人工試算發放</div>
          ) : (
            <Popconfirm
              okText="確認"
              cancelText="取消"
              title="確認核發時數？"
              disabled={targetProduct.non_publish_hour}
              onConfirm={async () => {
                try {
                  AppActions.setLoading(true);

                  await CustomAppActions.postLiveStreamTbaHours({
                    product_id: record.product,
                  });

                  message.success('核發成功');
                } catch (err) {
                  console.warn(err);
                  message.error('核發失敗');
                } finally {
                  AppActions.setLoading(false);
                }
              }}>
              <Button type="primary" disabled={targetProduct.non_publish_hour}>
                核發時數
              </Button>
            </Popconfirm>
          )}
        </div>
      ) : null;
    })();

    return [true, <div>{display}</div>];
  } else if (col.customType === 'fee_notify_template_name') {
    let display = getAnnualFeeTemplateNameLabel(record[col.key]);
    let url = `/admin/fee-notify-backup/?action=detail&id=${record.id}`;

    return [true, <AdminLinkButton title={display} url={url} />];
  } else if (col.customType === 'id_card_number') {
    const targetUser = UsersOutlet.getValue().find(
      (user) => user.owner === record[col.key],
    );
    const display = targetUser ? targetUser.id_card_number : '---';
    return [true, <div>{display}</div>];
  } else if (col.customType === 'user_username') {
    const display = record[col.key];
    const url = `${path}/?action=detail&id=${record.id}`;

    return [
      true,
      <AdminLinkButton title={display} url={url} style={{padding: 0}} />,
    ];
  } else if (col.customType === 'product_name') {
    const url = record.is_old
      ? (() => {
          switch (record.type) {
            case 'course':
              return `/admin/legacy-courses/?action=detail&id=${record.id}`;
            case 'ecourse':
              return `/admin/legacy-ecourses/?action=detail&id=${record.id}`;
            case 'activity':
              return `/admin/legacy-activities/?action=detail&id=${record.id}`;
            default:
              return `/admin/products/?action=detail&id=${record.id}`;
          }
        })()
      : `/admin/products/?action=detail&id=${record.id}`;

    const display = record.is_old
      ? (() => {
          switch (record.type) {
            case 'course':
              return `[${record.CLASS_NO || '---'}] ${record.NAME}`;
            case 'ecourse':
              return `[${record.CLASS_NO || '---'}] ${record.NAME}`;
            case 'activity':
              return `[${record.ID || '---'}] ${record.ACT_NAME}`;
            default:
              return `${record[col.key]}`;
          }
        })()
      : `${record.serial_number ? `[${record.serial_number}] ` : ''}${
          record[col.key]
        }`;

    const isSession =
      record.session &&
      Object.keys(record.session).length > 0 &&
      record.session.date;

    const sessionInfo = isSession && (
      <div>
        {record.session.date}
        {record.session.start_time && ` ${record.session.start_time}-`}
        {record.session.end_time && `${record.session.end_time}`}
      </div>
    );

    const isLegacySessions = record.is_old
      ? (() => {
          if (record.type === 'course') {
            return (
              record.course_session &&
              Array.isArray(record.course_session) &&
              record.course_session.length > 0
            );
          } else if (record.type === 'activity') {
            return (
              record.activity_session &&
              Array.isArray(record.activity_session) &&
              record.activity_session.length > 0
            );
          }

          return false;
        })()
      : false;

    const legacySessionInfo = (() => {
      if (isLegacySessions && record.type === 'course') {
        return (
          <div>
            {record.course_session.map((c) => (
              <div>{c.SESSION_TIME.slice(0, 16)}</div>
            ))}
          </div>
        );
      } else if (isLegacySessions && record.type === 'activity') {
        return (
          <div>
            {record.activity_session.map((a) => (
              <div>
                <div>
                  起：{a.EFF_DATE?.split('T')[0]}
                  {a.START_TIME ? ` ${a.START_TIME.slice(0, -3)}` : ' '}
                </div>
                <div>
                  迄：{a.EXPR_DATE?.split('T')[0]}{' '}
                  {a.END_TIME ? `${a.END_TIME?.slice(0, -3)}` : ''}
                </div>
              </div>
            ))}
          </div>
        );
      }

      return null;
    })();

    return [
      true,
      <div>
        <AdminLinkButton
          title={display}
          url={url}
          style={{
            padding: 0,
            maxWidth: 320,
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
          }}
        />
        {legacySessionInfo}
        {sessionInfo}
      </div>,
    ];
  } else if (col.customType === 'product_expertises') {
    const display = record.is_old
      ? record['METIER_ID']?.CIL_NAME
      : record[col.key]?.map((l, idx) => <span key={idx}>{l}</span>);

    return [true, <div>{display}</div>];
  } else if (col.customType === 'product_type') {
    const display = (() => {
      if (record.is_old) {
        if (record.type === 'course') {
          return '課程＃舊';
        } else if (record.type === 'ecourse') {
          return '影音＃舊';
        } else if (record.type === 'activity') {
          return '活動＃舊';
        }
      }

      if (
        record.internal_label === '在職進修' ||
        record.internal_label === '採認進修' ||
        record.internal_label === '專錄影片'
      ) {
        return '課程';
      }

      if (
        record.internal_label === '座談研討會' ||
        record.internal_label === '午間小品' ||
        record.internal_label === '拜會' ||
        record.internal_label === '接待' ||
        record.internal_label === '律師節活動' ||
        record.internal_label === '外部活動' ||
        record.internal_label === '外租場地' ||
        record.internal_label === '其他'
      ) {
        return '活動';
      }

      return '未分類';
    })();

    return [true, <div>{display}</div>];
  }

  return [false, null];
}

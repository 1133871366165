import React from 'react';
import {Tag, Button} from 'antd';
import {getOutlet} from 'reconnect.js';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import * as OrderCustomUtil from '../../Utils/OrderCustomUtil';
import * as CustomAdminResource from '../../Utils/CustomAdminResource';
import useAdminResourceInit from '../../Hooks/useAdminResourceInit';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as AdminOrderWidget from './AdminOrderWidget';
import * as AdminStockWidget from './AdminStockWidget';
import AdminSelectUserWidget from './AdminSelectUserWidget';
import AdminSelectProductWidget from './AdminSelectProductWidget';
import AdminSelectArticleWidget from './AdminSelectArticleWidget';
import AdminSelectUserCustomWidget from './AdminSelectUserCustomWidget';
import AdminSelectStaffWidget from './AdminSelectStaffWidget';
import AdminSelectCompanyWidget from './AdminSelectCompanyWidget';
import AdminSelectCommitteeWidget from './AdminSelectCommitteeWidget';
import AdminSelectCommitteeSummaryWidget from './AdminSelectCommitteeSummaryWidget';
import AdminVideoUploadWidget from './AdminVideoUploadWidget';
import AdminSelectMeetingWidget from './AdminSelectMeetingWidget';
import AdminSelectReceiveWidget from './AdminSelectReceiveWidget';
import AdminSelectPostWidget from './AdminSelectPostWidget';
import AdminSelectMoraleWidget from './AdminSelectMoraleWidget';
import AdminDateTimeWidget from './AdminDateTimeWidget';
import AdminSelectIntExcWidget from './AdminSelectIntExcWidget';
import {
  AdminAddressCityWidget,
  AdminAddressZipCodeWidget,
} from './AdminAddressWidget';
import AdminSelectSchoolWidget from './AdminSelectSchoolWidget';
import AdminSelectAnnualFeeOrderWidget from './AdminSelectAnnualFeeOrderWidget';
import AdminOrderDetailForm from './AdminOrderDetailForm';
import AdminProductShareButton from './AdminProductShareButton';
import AdminProductStockSummary from './AdminProductStockSummary';
import AdminPrivateProfile from './AdminPrivateProfile';
import AdminLinkButton from './AdminLinkButton';
import AdminSiteConfigButton from './AdminSiteConfigButton';
import AdminArticleBelowSection from './AdminArticleBelowSection';
import AdminPageBuilderButtonAction from './AdminPageBuilderBottonAction';
import {handleProducts} from './handleProducts';
import {handleArticles} from './handleArticles';
import {handleOrders} from './handleOrders';
import {handleOrdersBulkWrite} from './handleOrdersBulkWrite';
import {handleStocks} from './handleStocks';
import {handleConfigs} from './handleConfigs';
import {handleRebuildHistory} from './handleRebuildHistory';
import {handleExtra} from './handleExtra';
import {cloneDeep, isNaN} from 'lodash';
import VideoUploadModal from '../../Components/VideoUploadModal';
import AdminInsuranceField from './AdminInsuranceField';
import AdminVegetarianField from './AdminVegetarianField';
import AdminDescriptionsField from './AdminDescriptionsField';
import AdminLegacyFileField from './AdminLegacyFileField';
import AdminLegacySelectFormField from './AdminLegacySelectFormField';
import AdminLegacyTimeDisplayWidget from './AdminLegacyTimeDisplayWidget';

const ProductsOutlet = getOutlet('products');
const StaffsOutlet = getOutlet('staffs');
const UsersOutlet = getOutlet('users');

function AdminVideoUploadButton(props) {
  const {record} = props;
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Button type="primary" onClick={() => setShowModal(true)}>
        上傳新影片
      </Button>

      <VideoUploadModal
        visible={showModal}
        setVisible={setShowModal}
        record={record}
      />
    </>
  );
}

function AdminResourcePage(props) {
  const {location, path} = props;
  const pageContext = cloneDeep(props.pageContext);
  useAdminResourceInit({location});

  function renderCustomAdminSection(props) {
    const {name, type, context} = props;
    const [
      isOverride,
      overrideResp,
    ] = CustomAdminResource.renderCustomAdminSection({...props, path});

    if (isOverride) {
      return overrideResp;
    }

    if (
      type === 'form' &&
      name === 'AdminOrderDetailForm' &&
      context.position === 'bottom'
    ) {
      return <AdminOrderDetailForm context={context} />;
    } else if (type === 'form' && name === 'ArticleForm') {
      if (context.position === 'top') {
        return <h2 style={{margin: '15px 0'}}>基本資料</h2>;
      }

      if (context.position === 'below-form') {
        return (
          <AdminArticleBelowSection
            context={context}
            name={name}
            type={type}
            path={path}
            multilingual={path === '/admin/i18n-articles'}
          />
        );
      }
    } else if (
      type === 'form' &&
      name === 'ProductForm' &&
      context.position === 'bottom'
    ) {
      return <AdminProductShareButton context={context} />;
    } else if (name === 'UserCustom' && context.position === 'top') {
      return <AdminPrivateProfile context={context} />;
    } else if (
      type === 'resource' &&
      path.indexOf('/admin/orders/') !== -1 &&
      context.position === 'middle'
    ) {
      // return <AdminOrderWidget.OrderExtraQueries {...context} />;
      return null;
    } else if (
      type === 'form' &&
      path.indexOf('/admin/pages') !== -1 &&
      context.position === 'below-form'
    ) {
      return <AdminPageBuilderButtonAction location={location} {...props} />;
    }
    return null;
  }

  function renderCustomAdminCol(props) {
    const {col, record} = props;
    const [
      isOverride,
      overrideResp,
    ] = CustomAdminResource.renderCustomAdminCol({...props, path});

    if (isOverride) {
      return overrideResp;
    }

    if (col.customType === 'labels') {
      return record.labels?.map((l, idx) => <Tag key={idx}>{l}</Tag>);
    } else if (col.customType === 'label') {
      return record.label?.map((l, idx) => <Tag key={idx}>{l}</Tag>);
    } else if (col.customType === 'site-config-name') {
      return <AdminSiteConfigButton record={record} />;
    } else if (col.customType === 'order_status') {
      return <AdminOrderWidget.OrderStatusCustomElem record={record} />;
    } else if (col.customType === 'order_ui_status') {
      return <AdminOrderWidget.OrderUiStatusCustomElem record={record} />;
    } else if (col.customType === 'payment_status') {
      return <AdminOrderWidget.PaymentStatusCustomElem record={record} />;
    } else if (col.customType === 'payment_subtype') {
      return <AdminOrderWidget.PaymentSubtypeCustomElem record={record} />;
    } else if (col.customType === 'logistics_status') {
      return <AdminOrderWidget.LogisticsStatusCustomElem record={record} />;
    } else if (col.customType === 'logistics_type') {
      return <AdminOrderWidget.LogisticsTypeCustomElem record={record} />;
    } else if (col.customType === 'offline_tx') {
      return <AdminOrderWidget.OfflineTxCustomElem record={record} />;
    } else if (col.customType === 'stock_type') {
      return <AdminProductStockSummary record={record} />;
    } else if (col.customType === 'stock_amount') {
      if (!record.stock_type || record.stock_type === 'always') {
        return '-- --';
      }
      return record.stock_amount;
    } else if (col.customType === 'stock_sold_amount') {
      if (!record.stock_type || record.stock_type === 'always') {
        return '-- --';
      }
      return record.stock_sold_amount;
    } else if (col.customType === 'stock_value') {
      return <AdminStockWidget.StockValueCustomElem value={record.value} />;
    } else if (col.customType === 'stock_remaining') {
      return record.to - record.stock_sold_amount;
    } else if (col.customType === 'return_id') {
      return (
        <AdminLinkButton
          title={record.id}
          url={`/admin/orders/return/?action=detail&id=${record.id}`}
        />
      );
    } else if (col.customType === 'return_order') {
      return (
        <AdminLinkButton
          title={record.order_id || '訂單詳情'}
          url={`/admin/order/?action=detail&id=${record.order}`}
        />
      );
    } else if (col.customType === 'return_reason') {
      return record.data?.reason;
    } else if (col.customType === 'return_status') {
      return <AdminOrderWidget.ReturnStatusCustomElem record={record} />;
    } else if (col.customType === 'coupon_type') {
      return (
        <AdminLinkButton
          title={record.type === 'global' ? '全站' : '個人'}
          url={`/admin/coupons/?action=detail&id=${record.id}`}
        />
      );
    } else if (col.customType === 'mux-upload') {
      return <AdminVideoUploadButton record={record} />;
    } else if (col.customType === 'page-preview') {
      return (
        <a
          href={`/page-preview/?id=${record.id}`}
          target="_blank"
          rel="noreferrer">
          立即查看
        </a>
      );
    } else if (col.customType === 'survey_link') {
      return (
        <AdminLinkButton title={'問卷連結'} url={`/survey/?id=${record.id}`} />
      );
    } else if (col.customType === 'survey_stats') {
      return (
        <AdminLinkButton
          title={'統計結果'}
          url={`/survey/result/?id=${record.id}`}
        />
      );
    } else if (col.customType === 'survey_resp_values') {
      return (
        <div>
          {(record.values || []).map((value, idx) => {
            if (typeof value === 'string') {
              return <div key={idx}>{`${idx + 1} / ${value}`}</div>;
            } else if (typeof value === 'boolean') {
              return (
                <div key={idx}>{`${idx + 1} / ${value ? 'Yes' : 'No'}`}</div>
              );
            } else if (typeof value === 'object') {
              return (
                <div key={idx}>{`${idx + 1} / ${Object.keys(value)
                  .map((v) => parseInt(v, 10) + 1)
                  .join(',')}`}</div>
              );
            }
            return <div key={idx}>{`${idx + 1} / ---`}</div>;
          })}
        </div>
      );
    } else if (col.customType === 'survey_resp_owner') {
      if (!record.owner) {
        return '---';
      }

      return (
        <Button
          type="link"
          onClick={async () => {
            const upr = await JStorage.fetchOneDocument('user_profile', {
              owner: record.owner,
            });

            window.open(`/admin/users/?action=detail&id=${upr.id}`);
          }}>
          查看答題者
        </Button>
      );
    } else if (col.customType === 'survey_resp_go_to_survey') {
      return (
        <AdminLinkButton
          title={'查看問卷'}
          url={`/survey/result/?id=${record.survey}`}
        />
      );
    }

    return null;
  }

  handleProducts({path, pageContext});
  handleArticles({path, pageContext});
  handleOrders({path, pageContext});
  handleOrdersBulkWrite({path, pageContext});
  handleStocks({path, pageContext});
  handleConfigs({path, pageContext});
  handleRebuildHistory({path, pageContext});
  handleExtra({path, pageContext});

  const rjsfProps = {
    widgets: {
      'admin-select-user-widget': AdminSelectUserWidget,
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-article-widget': AdminSelectArticleWidget,
      'admin-date-time-widget': AdminDateTimeWidget,
      //tba custom logic
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
      'admin-select-staff-widget': AdminSelectStaffWidget,
      'admin-select-company-widget': AdminSelectCompanyWidget,
      'admin-select-committee-summary-widget': AdminSelectCommitteeSummaryWidget,
      'admin-select-meeting-widget': AdminSelectMeetingWidget,
      'admin-select-receive-widget': AdminSelectReceiveWidget,
      'admin-select-post-widget': AdminSelectPostWidget,
      'admin-select-morale-widget': AdminSelectMoraleWidget,
      'admin-select-committee-widget': AdminSelectCommitteeWidget,
      'admin-select-international-exchange-widget': AdminSelectIntExcWidget,
      'admin-select-school-widget': AdminSelectSchoolWidget,
      'admin-select-annual-fee-order-widget': AdminSelectAnnualFeeOrderWidget,
      'admin-video-upload-widget': AdminVideoUploadWidget,
      'admin-address-city-widget': AdminAddressCityWidget,
      'admin-address-zip-code-widget': AdminAddressZipCodeWidget,
      'admin-legacy-time-display-widget': AdminLegacyTimeDisplayWidget,
    },
    fields: {
      'admin-insurance-field': AdminInsuranceField,
      'admin-vegetarian-field': AdminVegetarianField,
      'admin-descriptions-field': AdminDescriptionsField,
      'admin-legacy-file-field': AdminLegacyFileField,
      'admin-legacy-select-form-field': AdminLegacySelectFormField,
    },
  };

  return (
    <AdminResource
      {...props}
      rjsfProps={rjsfProps}
      pageContext={pageContext}
      renderCustomAdminCol={renderCustomAdminCol}
      renderCustomAdminSection={renderCustomAdminSection}
      restructureDocuments={async (collection, jsStorageResult) => {
        if (collection === 'user_profile') {
          let uprs, pprs, companies;
          let nextUprs = [];

          const {results} = jsStorageResult;

          uprs = [...results];
          pprs = await JStorage.fetchAllDocuments('private_profile', {
            owner: {
              $in: uprs.map((p) => p.owner),
            },
          });

          if (uprs.filter((upr) => !!upr.company_current).length > 0) {
            companies = await JStorage.fetchAllDocuments(
              'Company',
              {
                _id: {
                  $in: uprs
                    .filter((upr) => !!upr.company_current)
                    .map((upr) => ({$oid: upr.company_current})),
                },
              },
              null,
              {
                id: 1,
                CONAME: 1,
                address_city: 1,
                address_zip_code: 1,
                address_detail: 1,
                COPHONE1: 1,
                COFAX1: 1,
              },
            );
          }

          for (let upr of uprs) {
            let ppr, company;
            ppr = pprs.find((ppr) => ppr.owner === upr.owner);
            company = companies.find(
              (company) => company.id === upr.company_current,
            );

            upr = {
              ...upr,
              points: ppr?.points || 0,
              provider: ppr?.provider || upr?.provider || '',
              status: ppr?.status,
              user_type: ppr?.user_type,
              company_current_detail: company ? {...company} : null,
            };

            nextUprs = [...nextUprs, upr];

            //tab custom logic
            if (path.indexOf('/admin/users-join-review') > -1) {
              nextUprs = nextUprs.filter(
                (record) =>
                  record.status === 'pending_checkout' ||
                  record.status === 'pending_review',
              );
            }
          }

          jsStorageResult.results = nextUprs;
        } else if (collection === 'checkout' && jsStorageResult.length !== 0) {
          return OrderUtil.mergeCheckoutJStorageRespWithOrder(jsStorageResult);
        } else if (
          collection === 'stock_record' &&
          jsStorageResult.length !== 0
        ) {
          const {results: stock_records} = jsStorageResult;
          const products = await JStorage.fetchAllDocuments('product', {
            _id: {
              $in: stock_records.map((stock_record) => ({
                $oid: stock_record.product_id,
              })),
            },
          });

          let nextStockRecords = [];
          for (let record of stock_records) {
            const product = products.find(
              (product) => product.id === record.product_id,
            );
            record = {
              ...record,
              name: product?.name,
            };
            nextStockRecords = [...nextStockRecords, record];
          }
          jsStorageResult.results = nextStockRecords;
        } else if (collection === 'review_request') {
          let rrs, companies;
          let nextRrs = [];

          const {results} = jsStorageResult;
          rrs = [...results];

          if (path.indexOf('/admin/company-reviews') > -1) {
            if (rrs.filter((rr) => !!rr.data.company_current).length > 0) {
              companies = await JStorage.fetchAllDocuments('Company', {
                _id: {
                  $in: rrs
                    .filter((rr) => !!rr.data.company_current)
                    .map((rr) => ({$oid: rr.data.company_current})),
                },
              });
            }

            for (let rr of rrs) {
              let company;
              if (!!rr.data.company_current) {
                company = companies.find(
                  (company) => company.id === rr.data.company_current,
                );
              }

              rr = {
                ...rr,
                ...rr.data,
                company_current_detail: company ? {...company} : null,
              };
              nextRrs = [...nextRrs, rr];
            }
          } else {
            for (let rr of rrs) {
              rr = {
                ...rr,
                ...rr.data,
              };

              nextRrs = [...nextRrs, rr];
            }
          }

          jsStorageResult.results = nextRrs;
        } else if (collection === 'company_billing') {
          let cbs, companies;
          let nextCbs = [];

          const {results} = jsStorageResult;
          cbs = [...results];
          companies = await JStorage.fetchAllDocuments(
            'Company',
            {
              _id: {$in: cbs.map((cb) => ({$oid: cb.company}))},
            },
            null,
            {
              id: 1,
              CONAME: 1,
              address_city: 1,
              address_zip_code: 1,
              address_detail: 1,
              COPHONE1: 1,
              COFAX1: 1,
            },
          );

          for (let cb of cbs) {
            const company = companies.find(
              (company) => company.id === cb.company,
            );

            cb = {
              ...cb,
              company_detail: company ? {...company} : null,
            };

            nextCbs = [...nextCbs, cb];
          }

          jsStorageResult.results = nextCbs;
        } else if (collection === 'return_request') {
          const {results: return_requests} = jsStorageResult;
          const orders = await JStorage.fetchAllDocuments(
            'order',
            {
              order_id: {$in: return_requests.map((rr) => rr.order_id)},
            },
            null,
            {id: 1, order_id: 1, items: 1, total: 1},
          );

          let nextReturnRequests = [];
          for (let record of return_requests) {
            const targetOrder = orders.find(
              (o) => o.order_id === record.order_id,
            );

            record = {
              ...record,
              items: targetOrder ? targetOrder.items : [],
              total: targetOrder ? targetOrder.total : 0,
            };

            nextReturnRequests = [...nextReturnRequests, record];
          }

          jsStorageResult.results = nextReturnRequests;
        } else if (collection === 'backup_email') {
          const countResults = await JStorage.aggregateDocuments(
            'backup_email_data',
            [
              {
                $match: {
                  backup_email_id: {
                    $in: jsStorageResult.results.map((r) => r.id),
                  },
                },
              },
              {
                $project: {
                  email: '$email',
                  backup_email_id: '$backup_email_id',
                },
              },
            ],
          );

          for (const r of jsStorageResult.results) {
            const counts = countResults.filter(
              (c) => c.backup_email_id === r.id,
            ).length;

            const counts_email_exists = countResults
              .filter((c) => c.backup_email_id === r.id)
              .filter((c) => !!c.email).length;

            const counts_email_not_exists = countResults
              .filter((c) => c.backup_email_id === r.id)
              .filter((c) => !c.email).length;

            r['counts'] = counts;
            r['counts_email_exists'] = counts_email_exists;
            r['counts_email_not_exists'] = counts_email_not_exists;
          }
        }

        if (
          path.indexOf('/admin/orders-course') > -1 ||
          path.indexOf('/admin/orders-activity') > -1
        ) {
          const {results: products} = jsStorageResult;

          const checkouts = await JStorage.fetchDocuments(
            'checkout',
            {
              'items.0.product.id': {
                $in: products.map((p) => ({
                  $oid: p.id,
                })),
              },
            },
            ['-created'],
            {offset: 0, limit: 10000},
            {
              order_id: 1,
              order_number: 1,
              items: 1,
              total: 1,
            },
          );

          const resp = await OrderCustomUtil.mergeCheckoutJStorageRespWithOrder(
            checkouts,
            {
              order_id: 1,
              order_number: 1,
              payment_status: 1,
            },
          );

          let nextRecords = [];
          for (let p of products) {
            const m = resp.results.filter(
              (r) => r.items[0].product.id.$oid === p.id,
            );

            const receiveable = m
              .map((r) => r.total)
              .reduce((acc, cur) => acc + cur, 0);

            const success = m
              .filter((r) => r.order_id)
              .filter((r) => r.payment_status === 'success')
              .map((r) => r.total)
              .reduce((acc, cur) => acc + cur, 0);

            p = {
              ...p,
              total_members: m.length,
              total_price_receiveable: receiveable,
              total_price_success: success,
              total_price_free: 0,
            };

            nextRecords = [...nextRecords, p];
          }

          jsStorageResult.results = nextRecords;
        } else if (path.indexOf('/admin/companies') > -1) {
          const countResults = await JStorage.aggregateDocuments('Company', [
            {
              $match: {
                CONAME: {$in: jsStorageResult.results.map((c) => c.CONAME)},
              },
            },
            {$addFields: {company_id: {$toString: '$_id'}}},
            {
              $lookup: {
                from: 'user_profile',
                localField: 'company_id',
                foreignField: 'company_current',
                as: 'profiles',
              },
            },
            {
              $addFields: {
                _profiles: {
                  $filter: {
                    input: '$profiles',
                    as: 'profile',
                    cond: {$eq: ['$$profile.state', 1]},
                  },
                },
              },
            },
            {
              $project: {
                count: {$size: '$profiles'},
                count_state_1: {$size: '$_profiles'},
              },
            },
          ]);

          for (const company of jsStorageResult.results) {
            const index = countResults.findIndex(
              (i) => i._id.$oid === company.id,
            );

            company['count'] = countResults[index].count;
            company['count_state_1'] = countResults[index].count_state_1;
          }
        }

        return jsStorageResult;
      }}
      modifyJStorageArgs={{
        modifyFetchDocumentsArgs: (...args) => {
          const [collection, query, ...restArgs] = args;
          let nextQuery = query;

          if (path.indexOf('/admin/receives') > -1) {
            const predefinedQueries = {
              $or: [
                {
                  type: 'receive',
                },
                {
                  is_old: true,
                  type: 'document_in',
                },
              ],
            };
            nextQuery = {
              $and: [predefinedQueries, nextQuery],
            };
          }
          if (path.indexOf('/admin/posts') > -1) {
            const predefinedQueries = {
              $or: [
                {
                  type: 'post',
                },
                {
                  is_old: true,
                  type: 'document_out',
                },
              ],
            };
            nextQuery = {
              $and: [predefinedQueries, nextQuery],
            };
          }

          if (collection === 'order' && !!query.__order_type) {
            if (query.__order_type === 'normal') {
              nextQuery = {
                ...nextQuery,
                is_custom: false,
                payment_subtype: {
                  $nin: [Cart.PAYMENT_SUBTYPE.offline],
                },
              };
            } else if (query.__order_type === 'offline') {
              nextQuery = {
                ...nextQuery,
                payment_subtype: Cart.PAYMENT_SUBTYPE.offline,
              };
            } else if (query.__order_type === 'custom') {
              nextQuery = {
                ...nextQuery,
                is_custom: true,
              };
            }

            delete nextQuery.__order_type;
          }

          if (collection === 'product' && !!query.__stock_amount) {
            nextQuery = {
              ...nextQuery,
              $and: [
                {stock_type: {$ne: 'always'}},
                {stock_amount: {$lte: query.__stock_amount}},
              ],
            };

            delete nextQuery.__stock_amount;
          }

          if (collection === 'sign_form') {
            if (query.$or && query.$or[0].product_id.$regex) {
              const regex = query.$or[0].product_id.$regex;
              const targetProducts = ProductsOutlet.getValue().filter(
                (p) => p.name?.indexOf(regex) > -1,
              );

              if (targetProducts.length > 0) {
                query.$or[0].product_id = {
                  $in: targetProducts.map((p) => p.id),
                };
              }
            }
          }

          if (collection === 'form') {
            const executorIndex = query.$or
              ? query.$or.findIndex((q) => !!q.executor)
              : -1;

            const implementIndex = query.$or
              ? query.$or.findIndex((q) => !!q.implement)
              : -1;

            const authorIndex = query.$or
              ? query.$or.findIndex((q) => !!q.author)
              : -1;

            if (executorIndex > -1) {
              const regex = query.$or[executorIndex].executor.$regex;
              const targetStaffs = StaffsOutlet.getValue().filter(
                (s) => s.name?.indexOf(regex) > -1,
              );

              if (targetStaffs.length > 0) {
                query.$or[executorIndex].executor = {
                  $in: targetStaffs.map((s) => s.id),
                };
              }
            }

            if (implementIndex > -1) {
              const regex = query.$or[implementIndex].implement.$regex;
              const targetStaffs = StaffsOutlet.getValue().filter(
                (s) => s.name?.indexOf(regex) > -1,
              );

              if (targetStaffs.length > 0) {
                query.$or[implementIndex].implement = {
                  $in: targetStaffs.map((s) => s.id),
                };
              }
            }

            if (authorIndex > -1) {
              const regex = query.$or[authorIndex].author.$regex;
              const targetStaffs = StaffsOutlet.getValue().filter(
                (s) => s.name?.indexOf(regex) > -1,
              );

              if (targetStaffs.length > 0) {
                query.$or[authorIndex].author = {
                  $in: targetStaffs.map((s) => s.id),
                };
              }
            }
          }

          if (collection === 'stock_record') {
            const productIndex = query.$or
              ? query.$or.findIndex((q) => !!q.product_id)
              : -1;

            if (productIndex > -1) {
              const regex = query.$or[0].product_id.$regex;

              const targetProducts = ProductsOutlet.getValue().filter(
                (p) => p.name?.indexOf(regex) > -1,
              );

              if (targetProducts.length > 0) {
                query.$or[0].product_id = {
                  $in: targetProducts.map((p) => p.id),
                };
              }
            }
          }

          if (collection === 'return_request') {
            const ownerIndex = query.$or
              ? query.$or.findIndex((q) => !!q.owner)
              : -1;

            if (ownerIndex > -1) {
              const regex = query.$or[0].owner.$regex;
              const targetUsers = UsersOutlet.getValue().filter(
                (u) => u.name?.indexOf(regex) > -1,
              );

              if (targetUsers.length > 0) {
                query.$or[0].owner = {$in: targetUsers.map((u) => u.owner)};
              }
            }
          }

          if (collection === 'party') {
            const projection = {
              title: 1,
              start_date: 1,
              start_time: 1,
              end_time: 1,
              address: 1,
              import_staff_name: 1,
              import_timestamp: 1,
            };

            return [collection, nextQuery, ...restArgs, projection];
          }

          return [collection, nextQuery, ...restArgs];
        },
      }}
      modifyKeywordQueries={async (
        path,
        {collection, keyword, originalQueries, searchFields},
      ) => {
        if (path.indexOf('/admin/users') > -1) {
          const nextQueries = [...originalQueries];

          const foreignResults = await JStorage.fetchAllDocuments(
            'Company',
            {
              $or: [
                {CONAME: {$regex: keyword}},
                {address_zip_code: {$regex: keyword}},
                {address_city: {$regex: keyword}},
                {address_detail: {$regex: keyword}},
                {COPHONE1: {$regex: keyword}},
                {COFAX1: {$regex: keyword}},
              ],
            },
            null,
            {_id: 1, CONAME: 1},
          );

          if (foreignResults.length > 0) {
            const ids = foreignResults.map((result) => result.id);
            nextQueries.push({
              company_current: {$in: ids.map((id) => id)},
            });
          }

          return [true, nextQueries];
        }

        if (path.indexOf('/admin/fee-notify-backup') > -1) {
          const nextQueries = [...originalQueries];

          const foreignResults = await JStorage.fetchAllDocuments(
            'backup_email_data',
            {$or: [{name: {$regex: keyword}}, {email: {$regex: keyword}}]},
            null,
            {backup_email_id: 1},
          );

          if (foreignResults.length > 0) {
            const ids = foreignResults.map((result) => result.backup_email_id);
            nextQueries.push({_id: {$in: ids.map((id) => ({$oid: id}))}});

            return [true, nextQueries];
          }
        }

        if (path.indexOf('/admin/room-reviews') > -1) {
          const nextQueries = [...originalQueries];
          nextQueries[0] = {'data.name': {$regex: nextQueries[0].name.$regex}};

          return [true, nextQueries];
        }

        if (path.indexOf('/admin/user-billings') > -1) {
          const nextQueries = [...originalQueries];

          const foreignResults = await JStorage.fetchAllDocuments(
            'user_profile',
            {id_card_number: {$regex: keyword}},
            null,
            {_id: 1, owner: 1},
          );

          if (foreignResults.length > 0) {
            const owners = foreignResults.map((result) => result.owner);
            nextQueries.push({owner: {$in: owners}});
          }

          return [true, nextQueries];
        }

        if (path.indexOf('/admin/company-billings') > -1) {
          const nextQueries = [...originalQueries];

          const foreignResults = await JStorage.fetchAllDocuments(
            'Company',
            {
              CONAME: {$regex: keyword},
            },
            null,
            {_id: 1, CONAME: 1},
          );

          if (foreignResults.length > 0) {
            const cIds = foreignResults.map((result) => result.id);
            nextQueries.push({company: {$in: cIds}});
          }

          return [true, nextQueries];
        }

        if (path.indexOf('/admin/products') > -1) {
          const nextQueries = [...originalQueries];
          const isNotNumber = isNaN(Number(keyword));

          if (!isNotNumber) {
            nextQueries.push({CLASS_NO: Number(keyword)});
            nextQueries.push({ID: Number(keyword)});
          }

          return [true, nextQueries];
        }

        if (path.indexOf('/admin/receives') > -1) {
          const nextQueries = [...originalQueries];

          nextQueries.push({'PROC_BY.NAME': {$regex: keyword}});
          nextQueries.push({'CREATE_BY.NAME': {$regex: keyword}});
          nextQueries.push({
            implements: {$elemMatch: {'DO_USER.NAME': {$regex: keyword}}},
          });

          return [true, nextQueries];
        }

        if (path.indexOf('/admin/posts') > -1) {
          const nextQueries = [...originalQueries];

          nextQueries.push({'PROC_BY.NAME': {$regex: keyword}});
          nextQueries.push({'CREATE_BY.NAME': {$regex: keyword}});
          nextQueries.push({
            implements: {$elemMatch: {'DO_USER.NAME': {$regex: keyword}}},
          });

          return [true, nextQueries];
        }

        return [false, null];
      }}
    />
  );
}

export default withPageEntry(AdminResourcePage);

import React from 'react';
import styled from 'styled-components';
import {Popconfirm, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import AdminLineDivider from '../../Components/AdminLineDivider';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';
import {formatDateStr} from '../../Utils/TimeUtil';
import permissionRequired, {
  appendSlashSuffix,
} from '../../Utils/PermissionRequiredUtil';

function AdminCompanyBillingActions(props) {
  const {context} = props;
  const {instance, values, setValues} = context;

  const [user] = useOutlet('user');
  const [company, setCompany] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState(null);

  return (
    <Wrapper id="root_custom-system-actions">
      <AdminLineDivider title="系統操作" />

      <div className="container">
        <Button
          style={{marginTop: 5, marginBottom: 5, marginRight: 10}}
          onClick={async () => {
            AppActions.setLoading(true);
            setDownloadUrl(null);

            if (Array.isArray(instance.group) && instance.group.length === 0) {
              AppActions.setLoading(false);
              return;
            }

            try {
              const company = await JStorage.fetchOneDocument(
                'Company',
                {
                  _id: {$oid: instance.company},
                },
                {CONAME: 1},
              );
              setCompany(company);

              let records = [];

              const owners = instance.group.map((item) => item.user);

              const users = await JStorage.fetchAllDocuments(
                'user_profile',
                {owner: {$in: owners}},
                null,
                {
                  owner: 1,
                  name: 1,
                  id_card_number: 1,
                  state: 1,
                  substate: 1,
                  birthday: 1,
                  inouts: 1,
                },
              );

              const seniorities = await AppActionsEx.getUserSeniorities({
                users_id: owners,
              });

              records = instance.group.map((r) => {
                const targetUser = users.find((user) => user.owner === r.user);

                //birthday
                const birthday = targetUser.birthday
                  ? formatDateStr(new Date(targetUser.birthday))
                  : '';

                //age
                const age_timestamp =
                  new Date().getTime() - new Date(targetUser.birthday);
                const age = `${parseInt(
                  age_timestamp / (365 * 24 * 60 * 60 * 1000),
                )}歲`;

                //seniority
                const seniority_obj = seniorities.find(
                  (user) => user.owner === r.user,
                );

                const seniority = seniority_obj.seniority;

                //join start date and end date
                let join_start_date = '';
                let join_end_date = '';

                if (
                  targetUser.inouts &&
                  Array.isArray(targetUser.inouts) &&
                  targetUser.inouts.length > 0
                ) {
                  const _inouts = [...targetUser.inouts]
                    .filter((r) => !!r.EFF_DATE)
                    .map((r) => ({
                      ...r,
                      EFF_DATE: r.EFF_DATE.split('T')[0],
                    }))
                    .sort(
                      (a, b) =>
                        new Date(b.EFF_DATE).getTime() -
                        new Date(a.EFF_DATE).getTime(),
                    );

                  join_start_date =
                    _inouts.length > 0 ? _inouts[0].EFF_DATE : '';

                  join_end_date =
                    _inouts.length > 0
                      ? _inouts[0].EXPR_DATE !== '9999-12-31T00:00:00'
                        ? _inouts[0].EXPR_DATE
                          ? _inouts[0].EXPR_DATE.split('T')[0]
                          : ''
                        : ''
                      : '';
                }

                return {
                  ...r,
                  annual_year: r.annual_year || instance.year,
                  total: r.price,
                  name: targetUser.name,
                  state: getUserStateLabel(targetUser.state),
                  substate: getUserSubstateLabel(targetUser.substate),
                  id_card_number: targetUser.id_card_number,
                  birthday,
                  age,
                  seniority,
                  join_start_date,
                  join_end_date,
                };
              });

              const recordsToAoa = [
                [
                  '編號',
                  '姓名',
                  '身分證字號',
                  '狀態',
                  '次狀態',
                  '生日',
                  '年齡',
                  '年資',
                  '入會日期',
                  '退會日期',
                  '年度',
                  '計費起月',
                  '計費迄月',
                  '金額',
                ],
                ...records.map((r, i) => {
                  return [
                    `${i + 1}`,
                    `${r.name}`,
                    `${r.id_card_number}`,
                    `${getUserStateLabel(r.state)}`,
                    `${getUserSubstateLabel(r.substate)}`,
                    `${r.birthday}`,
                    `${r.age}`,
                    `${r.seniority}`,
                    `${r.join_start_date}`,
                    `${r.join_end_date}`,
                    `${r.annual_year}`,
                    `${r.start_month}`,
                    `${r.end_month}`,
                    `${r.total}`,
                  ];
                }),
                [
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,
                  ``,

                  `${records.reduce((acc, cur) => acc + cur.total, 0)}`,
                ],
              ];

              const link = await generateExcelFile(recordsToAoa);
              if (link) {
                setDownloadUrl(link);
              }
            } catch (err) {
              console.warn(err);
              message.error('發生錯誤');
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          產生報表
        </Button>
        {downloadUrl && (
          <Button
            style={{marginTop: 5, marginBottom: 5, marginRight: 10}}
            type="link"
            download={`${instance.year}年度${company?.CONAME}事務所團繳.xlsx`}
            href={downloadUrl}
            target="_blank">
            下載報表
          </Button>
        )}
        <Popconfirm
          title="確認開立收據嗎？"
          okText="確認"
          cancelText="取消"
          onCancel={() => 0}
          onConfirm={async () => {
            if (!instance.receipt_admin_updater) {
              await JStorage.updateDocument(
                'company_billing',
                {id: instance.id},
                {receipt_admin_updater: user.id, is_receipt: true},
              );
            }

            const url = `/print-preview?collection=company_billing&id=${instance.id}`;
            window.open(url);
          }}>
          <Button style={{marginTop: 5, marginBottom: 5, marginRight: 10}}>
            開立收據
          </Button>
        </Popconfirm>
        <div style={{flex: 1}} />
        <Popconfirm
          title="確認結算嗎？"
          okText="確認"
          cancelText="取消"
          disabled={instance.payment_status === 'success'}
          onCancel={() => 0}
          onConfirm={async () => {
            const prompt = window.prompt(
              `結算完成後，這張事務所團繳單會切為「付款完成」，\n旗下常年會費訂單，付款狀態也將切為「付款完成」。\n請輸入您的姓名驗證權限：`,
            );

            const canWrite = permissionRequired(
              `${appendSlashSuffix(window.location.pathname)}_write`,
            );

            if (!prompt) {
              return;
            }

            if (prompt !== user.name || !canWrite) {
              message.error('驗證未通過，無法結算');
              return;
            }

            if (!instance.payment_offline_method) {
              message.error('請輸入線下方式');
              return;
            }

            if (!instance.offline_date) {
              message.error('請輸入線下收款日期');
              return;
            }

            if (prompt === user.name && canWrite) {
              try {
                message.info({
                  content: `正在進行結算，請勿關閉或重新整理頁面`,
                  duration: 0,
                });
                AppActions.setLoading(true);

                await AppActionsEx.adminPostCompanyBillingClose({
                  company_billing_id: instance.id,
                });

                message.destroy();
                message.success('結算完成');
                window.location.reload();
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              } finally {
                AppActions.setLoading(false);
              }
            }
          }}>
          <Button
            style={{marginTop: 5, marginBottom: 5, marginRight: 10}}
            type="primary"
            disabled={instance.payment_status === 'success'}>
            結算完成
          </Button>
        </Popconfirm>
      </div>
    </Wrapper>
  );
}

async function generateExcelFile(recordsToAoa, updateWorkSheet) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet(recordsToAoa);

    if (typeof updateWorkSheet === 'function') {
      updateWorkSheet(ws);
    }

    window.XLSX.utils.book_append_sheet(wb, ws, '團繳清單');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    message.success('成功創建下載連結');
    return objUrl;
  } catch (err) {
    console.warn('generateExcelFile ex', err);
  } finally {
    AppActions.setLoading(false);
  }
}

const Wrapper = styled.div`
  & > .container {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default AdminCompanyBillingActions;
